var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "wrap" }, [
        _c("div", { staticClass: "mr20 pic-403" }, [
          _c("img", {
            attrs: { src: require("@/assets/dcopslogo.png"), alt: "NOC" }
          })
        ]),
        _c("div", { staticClass: "ml20 desc-403" }, [
          _c("div", { staticClass: "title" }, [_vm._v("Sorry!")]),
          _c("div", { staticClass: "headline" }, [
            _vm._v(" 好像您没有访问IDC运营中心 DcOps 的权限哦... ")
          ]),
          _c("div", { staticClass: "info" }, [
            _vm._v(" 如需要申请访问，请联系 "),
            _c("span", { staticClass: "color-text" }, [_vm._v("idchelper")])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }