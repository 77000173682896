<template>
  <div class="container">
    <div class="wrap">
      <div class="mr20 pic-403">
        <img src="@/assets/dcopslogo.png" alt="NOC" />
      </div>

      <div class="ml20 desc-403">
        <div class="title">Sorry!</div>

        <div class="headline">
          好像您没有访问IDC运营中心 DcOps 的权限哦...
        </div>
        <div class="info">
          如需要申请访问，请联系 <span class="color-text">idchelper</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page403',
};
</script>

<style lang="less" scoped>
.container {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  .wrap {
    position: relative;
    display: flex;
    justify-content: center;
    width: 1200px;

    .pic-403 {
      animation-name: slideUp;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
    }

    .desc-403 {
      .title {
        margin-bottom: 30px;
        line-height: 40px;
        font-size: 32px;
        font-weight: bold;
        color: #1482f0;
        opacity: 0;
        animation-name: slideUp;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
      }

      .headline {
        margin-bottom: 30px;
        line-height: 24px;
        font-size: 20px;
        font-weight: bold;
        color: #222;
        opacity: 0;
        animation-name: slideUp;
        animation-duration: 0.5s;
        animation-delay: 0.1s;
        animation-fill-mode: forwards;
      }

      .info {
        margin-bottom: 50px;
        line-height: 21px;
        font-size: 13px;
        color: grey;
        opacity: 0;
        animation-name: slideUp;
        animation-duration: 0.5s;
        animation-delay: 0.2s;
        animation-fill-mode: forwards;
      }

      @keyframes slideUp {
        0% {
          transform: translateY(60px);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }
}
</style>
